
var processScroll = function($){

        var isContainerOnScreen = function(elem) {
                var $window = $(window),
                    viewport_top = $window.scrollTop(),
                    viewport_height = $window.height(),
                    viewport_bottom = viewport_top + viewport_height,
                    //top = elem.offset().top + (viewport_height / 2),
                    top = elem.offset().top + (viewport_height - 105),
                    height = elem.height(),
                    bottom = (top + height) - (viewport_height - 52);

                return (bottom > viewport_top && top < viewport_bottom);

            },
            isOnScreen = function(elem) {
                var $window = $(window),
                    viewport_top = $window.scrollTop(),
                    viewport_height = $window.height(),
                    viewport_bottom = viewport_top + viewport_height,
                    top = elem.offset().top + (viewport_height / 2),
                    height = elem.height(),
                    bottom = (top + height) - (viewport_height - elem.css('marginBottom').replace('px', ''));

                // console.log(elem.css('marginBottom').replace('px', ''));

                return (bottom > viewport_top && top < viewport_bottom);

            },
            setActiveCheckbox = function(el) {
                var listEl,
                    contentProcessID;

                contentProcessID = el.attr('data-process-id');
                listEl = $('.process-list').find('[data-process-id="' + contentProcessID + '"]');

                if(isOnScreen(el)){

                    if(!listEl.hasClass('checked')) {
                        listEl.addClass('checked');
                    }

                } else {
                    listEl.removeClass('checked');
                }
            },
            scrollToElement = function(el) {


                $('.sppb-addon-process').find('.checkbox-cntr').removeClass('checked');

                currentProcessID = $(el).attr('data-process-id');


                if(!$(el).hasClass('checked')){
                    $(el).addClass('checked');
                }

                isScrolling = true;
                $('html, body').animate({
                    scrollTop: $('.process-content').find('[data-process-id="' + currentProcessID + '"]').offset().top - (menuHeight + 30)
                }, 500, function(){
                    isScrolling = false;
                });

            },
            currentProcessID,
            listElement,
            containerElement,
            isScrolling = false,
            menuHeight = $('#sp-header').height(),
            processListContainer,
            processContainer;


        if($('.sppb-addon-process').length !== 0){

            listElement = $('.sppb-addon-process').find('.process-list-cntr');
            containerElement = $('.sppb-addon-process');
            processListContainer = $('.sppb-addon-process').find('.process-list-cntr');
            processContainer = $('.sppb-addon-process').find('.process-cntr');

            $(window).scroll(function(){

                if(isScrolling === false) {
                    processContainer.each(function(){
                        setActiveCheckbox($(this));
                    });
                }

                if(isContainerOnScreen($('.sppb-addon-process'))){
                    if(!processListContainer.hasClass('fixed')){
                        processListContainer.addClass('fixed');
                    }
                } else {
                    processListContainer.removeClass('fixed');

                }


                if($(window).scrollTop() > ((containerElement.offset().top + containerElement.height()) - listElement.height()) - (listElement.height() / 2)) {
                    if(!processListContainer.hasClass('absolute')){
                        processListContainer.addClass('absolute');
                    }
                } else {
                    processListContainer.removeClass('absolute');
                }

            });


            $('.sppb-addon-process').find('.checkbox-cntr').each(function(){
                $(this).click(function(){
                    scrollToElement(this);
                });
            });

        }
    }

export {processScroll};