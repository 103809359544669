import Player from '@vimeo/player';


var initPlayerControls = function($){

    var playVideo = function(player, cntr){

            //hide play button

            //hide preview image

            $(cntr).addClass('playing');

            player.play().then(function() {
                // the video was played
            }).catch(function(error) {
                switch (error.name) {
                    case 'PasswordError':
                        console.log('pass error');
                        // the video is password-protected and the viewer needs to enter the
                        // password first
                        break;

                    case 'PrivacyError':
                        console.log('privacy error');
                        // the video is private
                        break;

                    default:
                        // some other error occurred
                        console.log(error);
                        break;
                }
            });
        },
        pauseVideo = function(){

        };


    $('.iframe-cntr').each(function(){
            //var iframe = document.querySelector('.iframe-ctrl-player');
        var iframe = $(this).find('.iframe-ctrl-player'),
            player = new Player(iframe),
            self = this;

        player.on('play', function() {
            //console.log('played the video!');
        });

        player.getVideoTitle().then(function(title) {
            //console.log('title:', title);
        });


        $(this).find('.video-icon, .image-cntr').click(function(){
            //console.log('trying to play video');
            playVideo(player, self);
        });
    });


}

export {initPlayerControls};