var setSingleImage = function($, e, element){
    var returnBreakpointCode = function (breakpoint) {
            switch (breakpoint) {
                case "mobile-s-up":
                    breakpointCode = "ms";
                    break;
                case "mobile-m-up":
                    breakpointCode = "ms";
                    break;
                case "tablet-s-up":
                    breakpointCode = "ts";
                    break;
                case "tablet-m-up":
                    breakpointCode = "ts";
                    break;
                case "tablet-l-up":
                    breakpointCode = "ts";
                    break;
                case "desktop-s-up":
                    breakpointCode = "ds";
                    break;
                case "desktop-m-up":
                    breakpointCode = "ds";
                    break;
                case "desktop-l-up":
                    breakpointCode = "ds";
                    break;
                default:
                    break;
            }
            return breakpointCode;
        },
        breakpoint = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, ''),
        breakpointCode = returnBreakpointCode(breakpoint),
        el = $(element),
        imgUrl = el.attr('data-img-src'),
        imgUrlBase = imgUrl.substring(0,imgUrl.lastIndexOf("-")),
        imgUrlExt = imgUrl.substring(imgUrl.lastIndexOf(".")),
        imgSourceUrl,
        breakCode = breakpointCode === "dl" ? "dm" : breakpointCode;



    switch (imgUrlExt){
        case ".svg":
        if (onPageLoad) {
            imgSourceUrl = imgUrl;
            el.attr("src", imgSourceUrl);

        }
        break;
        case ".png":
        case ".jpg":
        case ".gif":
        imgSourceUrl = imgUrlBase + "-" + breakCode + imgUrlExt;

        el.attr("data-src", imgSourceUrl);
        break;
        default:
    }
    el.unveil();
    el.trigger("unveil");

};

export {setSingleImage};
