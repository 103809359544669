var modalImgSlider = function($, options){
    var set = $.extend(true, {
        slide: 'data-slide',
    }, options),
    slideAmount = 0, // $('[' + set.slidesCont + ']').attr(set.slidesCont),
    $activeSlide = $('[' + set.slide + '="0"]'),
    $mainEl = $('[' + set.main + ']'),
    $btns = {
        right: $ ('[' + set.btn + '="right"]'),
        left: $('[' + set.btn + '="left"]')
    },
    $slide = '',
    $navBtn = '',
    navDirection = '',
    btnRightSlidesAvailable ='',
    btnLeftSlidesAvailable ='',
    lastSlideActive,
    secondToLastSlideActive,
    setBtnsState = function (){},
    displayActiveSlide = function(){},
    clickBtn = function(btn, $slide, slideAmount) {
        $slide.activeSlideId = 0;

        btn.click(function() {
            btnRightSlidesAvailable = $slide.activeSlideId+1 < slideAmount;    
            btnLeftSlidesAvailable = $slide.activeSlideId  > 0;
            navDirection = $(this).attr(set.btn);
            $activeSlide = $slide.find($('[' + set.slide + '="' + $slide.activeSlideId + '"]')),
            displayActiveSlide = function () {
                switch(navDirection) {
                    case "right":
                        if (btnRightSlidesAvailable) {
                            $slide.activeSlideId += 1;
                        }
                    break;
                    case "left":
                        if (btnLeftSlidesAvailable) {
                            $slide.activeSlideId -= 1;
                        }
                    break;
                }
                
                if (btnRightSlidesAvailable || btnLeftSlidesAvailable)  {
                    $activeSlide.removeClass(set.elDisplayClass);
                    $activeSlide = $slide.find($('[' + set.slide + '="' + $slide.activeSlideId + '"]'));
                    $activeSlide.addClass(set.elDisplayClass);
                }
            },
            setBtnsState = function () {
                lastSlideActive = $slide.activeSlideId+1 === slideAmount;
                secondToLastSlideActive = $slide.activeSlideId+1 === slideAmount-1;
    
                if (lastSlideActive) {
                    $btns.right.removeClass(set.elDisplayClass);
                } else if (secondToLastSlideActive) {
                    $btns.right.addClass(set.elDisplayClass);
                }
    
                if ($slide.activeSlideId === 1) { 
                    $btns.left.addClass(set.elDisplayClass);
                } else if ($slide.activeSlideId === 0) {
                    $btns.left.removeClass(set.elDisplayClass);
                }
            };

            displayActiveSlide();
            setBtnsState();
        })
    }

    $mainEl.each(function(index) {
        $navBtn = $(this).find($('[' + set.btn + ']'));
        $slide = $(this);
        slideAmount = parseInt($slide.attr(set.slidesCont));
        clickBtn($navBtn , $slide,slideAmount);
    });

    if (slideAmount > 0 ) {
        $btns.right.addClass(set.elDisplayClass);
    }

    $activeSlide.addClass(set.elDisplayClass);
}

export {modalImgSlider};