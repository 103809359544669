var jceFix = function($){


    var waitForElement = function (elementPath, callBack){

            window.setTimeout(function(){
            if($(elementPath).length){
                callBack(elementPath, $(elementPath));
            }else{
                waitForElement(elementPath, callBack);
            }
            },500)
        },
        simulateClick = function(){
            $('#sp-pagebuilder-page-tools').mouseenter(function() {
                jQuery(this).click();


            });

        },
        resetFix = function(){
            $('.body-wrapper, .sp-pagebuilder-container').mouseenter(function() {
                fixSelection();

            });
        },
        fixSelection = function(){

            $(".sp-inline-editable-element").unbind('mousedown');

            $('.sp-inline-editable-element').on('mousedown', function(){
                $(this).click();
                $(this).closest('.sp-pagebuilder-edit-addon, .sp-pagebuilder-edit-addon::before, .sp-pagebuilder-addon-tools, .sp-pagebuilder-addon-tools::before, .addon-placeholder-top ').click();

            });
        };

    waitForElement('#sp-pagebuilder-page-tools', simulateClick);
    waitForElement('.sp-inline-editable-element', fixSelection);
    waitForElement('.sp-inline-editable-element', resetFix);

}

export {jceFix};