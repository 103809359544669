
import lottie from 'lottie-web/build/player/lottie_canvas.min.js';
var resizeTimer,
    resolvedPath,
    initLottie = function ($){
        var waitForElement = function (elementPath, callBack){
            //console.log('waiting for element');
            window.setTimeout(function(){
            if($(elementPath).length){
                callBack(elementPath, $(elementPath));
            }else{
                waitForElement(elementPath, callBack);
            }
            },500)
        },
        isInViewport = function(el) {
            var elementTop = el.offset().top;
            var elementBottom = elementTop + el.outerHeight();
            var viewportTop = jQuery(window).scrollTop();
            var viewportBottom = viewportTop + jQuery(window).height();
            return elementBottom > viewportTop && elementTop < viewportBottom;
        },
        isInActiveTab = function() {
            document.addEventListener("visibilitychange", function(event) {
                if (document.hidden){
                    lottie.stop();
                } else {
                    lottie.getRegisteredAnimations().forEach(function(animation) {
                        if (isInViewport(jQuery('#'+animation.wrapper.id))) {
                        animation.play();
                        }
                    });
                }
            });
        },
        setLottieAnimation = function(resizing) {

            jQuery('.lottie-animation').each(function(){

                var self = jQuery(this),
                    path = self.attr('data-path'),
                    currentPath,
                    lottieInstance,
                    id = self.attr('id'),
                    container = document.getElementById(id),
                    calcHeight;

                //use different path if in front end editor
                if(jQuery('#sp-pagebuilder-view').length !== 0 || jQuery('.layout-edit-iframe').length !== 0){

                    currentPath = window.location.href;

                    if(currentPath.indexOf('stageit') !== -1) {
                        resolvedPath = '/stageit/' + path;
                    } else {
                        resolvedPath = '/'+path;
                    }

                } else {
                    resolvedPath = path;
                }


                jQuery.getJSON( resolvedPath, function( data ) {

                    calcHeight = (self.width() / data.w) * data.h;

                    self.css('height', calcHeight + 'px');

                    lottieInstance = lottie.loadAnimation({
                        container: document.getElementById(id), // the dom element that will contain the animation
                        renderer: 'canvas',
                        loop: true,
                        autoplay: false,
                        //path: resolvedPath, // the path to the animation json
                        animationData: data,
                        rendererSettings: {
                            clearCanvas: true,
                            preserveAspectRatio: 'xMidYMid meet'
                        }
                    });
                    lottie.setQuality(1);
                    lottieInstance.setSpeed(1);
                    lottieInstance.setSubframe(false);
                    lottieInstance.stop();
                    
                    playWhenInView({data: {lottie: lottieInstance, id: id}});
                    jQuery(window).on('scroll', {lottie: lottieInstance, id: id}, playWhenInView);

                });
                
                
            });
        },
        playWhenInView = function(event){
            var ani = {
                isPaused : event.data.lottie.isPaused,
                isInViewport: isInViewport(jQuery('#'+event.data.id))
            };

            if(ani.isInViewport && ani.isPaused){
                event.data.lottie.play();
            } else {
                if(!ani.isInViewport && !ani.isPaused) {
                    event.data.lottie.stop();
                }
            }
        },
        setLottieOnResize = function (){
            jQuery(window).off('scroll', playWhenInView);
            lottie.destroy();
            setLottieAnimation(true);
        };

        waitForElement('.lottie-animation', setLottieAnimation);
        isInActiveTab();
        //setLottieAnimation(false);
        var width = $(window).width();

        jQuery(window).resize(function() {
            if ($(window).width() != width) {

                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(setLottieOnResize, 1000);
            }
        });  
    }
export {initLottie};
