var goToAnchor = function($){

    var scrollToTarget = function() {

        $("a[href^=#]").click(function(e) {

            e.preventDefault();
            var dest = $(this).attr('href');

            if($(dest).length != 0 && dest.indexOf('sppb-modal') === -1) {
                $('html,body').animate({ scrollTop: $(dest).offset().top - 100 }, '1500');
            }

        });
    }
    scrollToTarget();
}


export {goToAnchor};