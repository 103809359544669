var detectFirstBlock = function($){
    var delayDetection = function(){

            firstBlock = $('#sp-page-builder > #sp-pagebuilder-container > div > .sp-pagebuilder-section-parent:first-child .sppb-section');
            firstBlockClass = $(firstBlock).attr('class');

            if(firstBlock.length === 0) {
                //console.log('first block not found');
            } else {
                setHeaderClass(firstBlockClass);
            }

        },
        setHeaderClass = function(firstBlockClass){

            if (firstBlockClass.indexOf('bg-type-image') !== -1 ) {
                blockColor = 'image';
            } else if (firstBlockClass.indexOf('bg-type-burple') !== -1 ) {
                blockColor = 'burple';
            } else if (firstBlockClass.indexOf('bg-type-white') !== -1 ) {
                blockColor = 'white';
            } else if (firstBlockClass.indexOf('bg-type-orange') !== -1 ) {
                blockColor = 'orange';
            } else {
                blockColor = 'burple';
            }

            $('#sp-header').find('.container').addClass(blockColor);

        },
        waitForElement = function (elementPath, callBack){
            window.setTimeout(function(){
              if($(elementPath).length){
                callBack(elementPath, $(elementPath));
              }else{
                waitForElement(elementPath, callBack);
              }
            },500)
        },
        firstBlock = $('#sp-page-builder > .page-content > .sppb-section:first-child'),
        firstBlockClass = $(firstBlock).attr('class'),
        blockColor;

    if(firstBlock.length === 0) {
        //setTimeout(delayDetection, 10000);
        waitForElement('#sp-page-builder > #sp-pagebuilder-container .sp-pagebuilder-section-parent', delayDetection);
    } else {
        setHeaderClass(firstBlockClass)
    }
}



export {detectFirstBlock};